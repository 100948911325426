<template lang="pug">
v-footer(dark color="navy")
  v-row(justify='end')
    a(href='https://selflane.com') &copy; {{ new Date().getFullYear() }} Selflane.com
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
