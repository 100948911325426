import Vue from 'vue'
import vuetify from './plugins/vuetify';
import './style/main.styl'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import VueAuth from '@websanova/vue-auth'
import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta'

import * as filters from './filters'
const baseURL = process.env.VUE_APP_MAIN_API

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = baseURL
Vue.axios.defaults.withCredentials = true

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueAnalytics, {
  id: 'UA-111753270-9',
  router,
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  }
})

Vue.config.productionTip = false
Vue.router = router
Vue.use(VueAuth, {
  auth: {
    request: function (req, token) {
      this.options.http._setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
      })
    },
    response: function () {
      return 'selflane.com'
    }
  },
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
  loginData: {
    url: '/users/login',
    fetchUser: true
  },
  logoutData: {
    url: '/users/logout',
    method: 'get',
    redirect: '/login',
    makeRequest: true
  },
  fetchData: {
    url: '/users/get',
    method: 'GET',
    enabled: true
  },
  rememberMe: true,
  refreshData: {
    enabled: false
  },
  parseUserData: (data) => {
    return data
  },
  googleOauth2Data: {
    params: {
      redirect_uri: baseURL + '/auth/google/callback'
    },
    clientId: '533775739257-rt4hjb2mvbpdhsiaskh207n97ou4mv0p.apps.googleusercontent.com'
  }
})

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// a clean http instance for 3rd party (googleaip) calls
const axios2 = axios.create({
  withCredentials: false
})
window.axios2 = axios2