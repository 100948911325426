<template lang="pug">
v-hover(v-slot:default="{ hover }")
  v-card(shaped :elevation="hover ? 12 : 2")
    v-img(:src='article.img' height='220' cover)
    v-card-title {{ article.title }}
    v-card-text
      span {{ article.content }}
    v-card-actions
      v-btn(color="orange" text :href='article.file') Read More
</template>
<script>
export default {
  props: ["article"]
};
</script>
