<template lang="pug">
div
  .display-1 Shop
  v-row(wrap justify='center')
    v-col(cols='12' sm='6' md='4' lg='3' v-for='(item, index) in catalogGroups' :key='index')
      v-card
        v-img(:src='mediaHost + item.icon' v-if='item.icon')
        v-card-title {{ item.name }}
  div(v-for='(item, index) in catalogGroups' :key='index')
    Group(:group='item')
</template>

<script>
import Group from "@/components/Catalog/Group/index.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  components: { Group },
  data() {
    return {
      mediaHost: "https://s3-us-west-2.amazonaws.com/media.selflane.com/"
    };
  },
  computed: {
    ...mapGetters(["catalogGroups"])
  },
  methods: {
    ...mapActions(["setCatalogGroups", "setCatalogSubGroups", "setProducts"]),
    loadGroups() {
      const options = {
        criteria: {}
      };
      this.axios.post("/catalog/group/list", options).then(response => {
        const data = _.sortBy(response.data, "name");
        this.setCatalogGroups(data);
      });
    },
    loadSubGroups() {
      const options = {
        criteria: {}
      };
      this.axios.post("/catalog/subgroup/list", options).then(response => {
        this.setCatalogSubGroups(response.data);
      });
    },
    loadProducts() {
      const options = {
        criteria: {}
      };
      this.axios.post("/catalog/product/list", options).then(response => {
        this.setProducts(response.data);
      });
    }
  },
  mounted() {
    this.loadGroups();
    this.loadSubGroups();
    this.loadProducts();
  }
};
</script>
