import _ from 'underscore'

const state = {
  delivOrders: []
}

const getters = {
  delivOrders: state => state.delivOrders
}

const actions = {
  setDelivOrders: ({
    commit
  }, delivOrders) => {
    commit('setDelivOrders', delivOrders)
  },
  updateDelivOrder: ({
    commit
  }, data) => {
    commit('updateDelivOrder', data)
  }
}

const mutations = {
  setDelivOrders(state, delivOrders) {
    state.delivOrders = delivOrders
  },
  updateDelivOrder(state, data) {
    state.delivOrders = _.map(state.delivOrders, o => {
      if (o._id === data._id) return data
      return o
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}