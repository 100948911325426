<template lang="pug">
div(v-if='subgroup')
  .subtitle-2 {{ subgroup.name }}
  v-row(wrap)
    v-col(cols='12' sm='6' md='4' lg='3' v-for='(item, index) in filteredProducts' :key='index')
      Product(:product='item')
</template>

<script>
import _ from "underscore";
import Product from "./Product";
import { mapGetters } from "vuex";

export default {
  props: ["subgroup"],
  components: { Product },
  computed: {
    ...mapGetters(["products"]),
    filteredProducts() {
      if (!this.subgroup || !this.products) return;
      const data = _.chain(this.subgroup.products)
        .map(id => {
          const found = _.find(this.products, o => o._id == id);
          return found;
        })
        .compact()
        .value();
      return data;
    }
  }
};
</script>