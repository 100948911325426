<template lang="pug">
div
  Head
  List
  .mb-10
</template>
<script>
import Head from "./Head";
import List from "./List";

export default {
  name: "CaseStudies",
  metaInfo: {
    title: "Case Studies",
    titleTemplate: "%s | Selflane",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Case studies performed with restaurants using Selflane"
      },
      {
        name: "keywords",
        content: "Selflane, Restaurant System, Restaurant Satisfaction"
      }
    ]
  },
  components: { Head, List }
};
</script>
