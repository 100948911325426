import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/User/Login/index.vue";
import ResetPWD from "@/components/User/Login/ResetPWD.vue";

import pagesRoute from '@/pages/route'

Vue.use(Router);

const baseRoutes = [{
    path: "/login",
    component: Login
  }, {
    path: "/resetPWD",
    component: ResetPWD
  },
  {
    path: "*",
    redirect: "/"
  }
]

const routes = baseRoutes.concat(pagesRoute)

export default new Router({
  mode: "history",
  linkActiveClass: "is-active",
  scrollBehavior: () => ({
    y: 0
  }),
  routes: routes
});