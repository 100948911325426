<template lang="pug">
v-container
  Catalog
</template>

<script>
import Catalog from "./Catalog";
export default {
  name: "Shop",
  components: { Catalog },
  metaInfo: {
    title: "Shop",
    titleTemplate: "%s | Selflane",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Shopping Selflane POS Hardwares and Services"
      },
      {
        name: "keywords",
        content: "Selflane, Restaurant System, iPad POS, Credit Card Processing"
      }
    ]
  }
};
</script>
