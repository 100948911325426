<template lang="pug">
div(v-if='group')
  .title.my-5 {{ group.name }}
  div(v-for='(item, index) in filteredSubGroups' :key='index')
    SubGroup(:subgroup='item')
</template>

<script>
import _ from "underscore";
import SubGroup from "./SubGroup";
import { mapGetters } from "vuex";

export default {
  props: ["group"],
  components: { SubGroup },
  computed: {
    ...mapGetters(["catalogSubGroups", "catalogProducts"]),
    filteredSubGroups() {
      if (!this.group || !this.catalogSubGroups) return [];
      const data = _.chain(this.group.subgroups)
        .map(id => {
          const found = _.find(this.catalogSubGroups, o => o._id == id);
          return found;
        })
        .compact()
        .value();
      return data;
    }
  }
};
</script>
