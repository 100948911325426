import _ from 'underscore'

const state = {
  catalogSubGroups: []
}

const getters = {
  catalogSubGroups: state => state.catalogSubGroups
}

const actions = {
  setCatalogSubGroups: ({
    commit
  }, data) => {
    commit('setCatalogSubGroups', data)
  },
  addCatalogSubGroup: ({
    commit
  }, data) => {
    commit('addCatalogSubGroup', data)
  },
  updateCatalogSubGroup: ({
    commit
  }, data) => {
    commit('updateCatalogSubGroup', data)
  },
  removeCatalogSubGroup: ({
    commit
  }, data) => {
    commit('removeCatalogSubGroup', data)
  }
}

const mutations = {
  setCatalogSubGroups(state, data) {
    state.catalogSubGroups = data
  },
  addCatalogSubGroup(state, data) {
    state.catalogSubGroups = state.catalogSubGroups.concat([data])

  },
  updateCatalogSubGroup(state, data) {
    state.catalogSubGroups = _.map(state.catalogSubGroups, o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeCatalogSubGroup(state, data) {
    state.catalogSubGroups = _.reject(state.catalogSubGroups, o => {
      return o._id === data._id
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}