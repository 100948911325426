import _ from 'underscore'

const state = {
  catalogGroups: []
}

const getters = {
  catalogGroups: state => state.catalogGroups
}

const actions = {
  setCatalogGroups: ({
    commit
  }, data) => {
    commit('setCatalogGroups', data)
  },
  addCatalogGroup: ({
    commit
  }, data) => {
    commit('addCatalogGroup', data)
  },
  updateCatalogGroup: ({
    commit
  }, data) => {
    commit('updateCatalogGroup', data)
  },
  removeCatalogGroup: ({
    commit
  }, data) => {
    commit('removeCatalogGroup', data)
  }
}

const mutations = {
  setCatalogGroups(state, data) {
    state.catalogGroups = data
  },
  addCatalogGroup(state, data) {
    state.catalogGroups = state.catalogGroups.concat([data])
  },
  updateCatalogGroup(state, data) {
    state.catalogGroups = _.map(state.catalogGroups, o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeCatalogGroup(state, data) {
    state.catalogGroups = _.reject(state.catalogGroups, o => {
      return o._id === data._id
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}