import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import orders from './modules/orders'
import delivOrders from './modules/delivOrders'
import deliv from './modules/deliv'
import driverIds from './modules/driverIds'

import drivers from './modules/Driver/drivers'
import driverPayouts from './modules/Driver/driverPayouts'

import stripeAccount from './modules/Stripe/account'
import stripeBanks from './modules/Stripe/banks'
import stripePersons from './modules/Stripe/persons'

import catalogGroups from './modules/catalogGroups'
import catalogSubGroups from './modules/catalogSubGroups'
import products from './modules/products'


Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    orders,
    delivOrders,
    deliv,
    driverIds,
    drivers,
    driverPayouts,
    stripeAccount,
    stripeBanks,
    stripePersons,
    catalogGroups,
    catalogSubGroups,
    products
  }
})
export default store