<template lang="pug">
v-container
  v-row(wrap)
    v-col(cols='12' sm='6' md='4' v-for='(item, index) in articles' :key='index')
      Cell(:article='item')
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  data() {
    return {
      articles: [
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/case_studies%2Fvyna%2Framen_naruto_abq.jpg?alt=media&token=bb14e1c4-eced-4ff7-9fc7-f2984866edb6",
          title: "How Selflane Simplifed Order-Taking for This Ramen Bar",
          content:
            "There are times when Naruto is busy and chaotic. The servers feel at ease knowing that the orders are taken care of in the kitchen the moment they tap ‘send’. Any orders that need to be modified (i.e. adding or taking out an item) is easily done by tapping the ‘edit order’ button. Selflane is also continuously updating their app to further simplify this aspect. Another great aspect about Selflane is its ability to keep the customers who are coming in and leaving organized. Servers are able to see on their iPads which tables are occupied, how long the customers have been dining or waiting for their food, and which tables are open. One thing that the servers appreciate most is how the tips are adjusted with Selflane.",
          file:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/case_studies%2Fvyna%2Fselflane-case-study.pdf?alt=media&token=c49641a2-59bb-451c-8120-294600c372e4"
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/case_studies%2Fvyna%2Fstripes_abq.jpg?alt=media&token=0d69541f-24b7-4b47-ad74-78eb9c8b0c92",
          title: "Selflane Customizes to Fit Your Restaurant's Needs",
          content:
            "However, it didn’t take long for Sanchez to realize that Selflane was precisely what Stripes needed. “The system is super simple. My employees caught on very quickly. The first time I used Selflane was at Stripes Burrito Co. and at the time, we were getting used to our new concept, which is the drive-thru. We were making up stuff as we went and the fact that the POS system was not something that we to worry about was extremely nice” commented Sanchez. A feature that Sanchez likes most about Selflane is customization. “Any time I think of something that would make the system even better, I would call Kevin and ask if we can do it. He would just be like alright, give me a week or two. Most of the time, Selflane will even modify my request instantly. If I could give Selflane a slogan, I would say: Selflane, the POS that modifies itself for your business”.",
          file:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/case_studies%2Fvyna%2FStripes%20Burrito%20Co.%20C.S.%20.pdf?alt=media&token=621adc4b-c03c-4d4e-97ef-68a77e5f4d0f"
        },
        {
          img:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/case_studies%2Fvyna%2Fdaily_grind_abq.jpeg?alt=media&token=984a02cd-6473-48bd-8681-15a4262c87a2",
          title:
            "This Restaurateur Claims That Selflane Has the Best Customer Service",
          content:
            "By using Selflane, Gutierrez noticed that he’s been saving more money due to the fact that he is able to monitor if his employees are discounting customers too much. In the past, Gutierrez saw in the reports provided by the previous POS systems that the discounts seemed to be quite high, but he didn’t know which employees were at fault. With Selflane’s detailed reports, Gutierrez says, “it spouts out how much each person discounts and then it prints out on the receipt every discount they do. It’s monitoring their honesty for lack of better words”.",
          file:
            "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/case_studies%2Fvyna%2FGreg%20G.%20Case%20study.pdf?alt=media&token=221e331b-a447-479b-8f61-779724cd88d0"
        }
      ]
    };
  }
};
</script>
