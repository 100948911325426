<template lang="pug">
v-app
  Nav
  v-content.body-2
    router-view
  Footer
</template>


<script>
import Nav from "./components/Nav/index";
import Footer from "./components/Footer/index";

export default {
  name: "App",
  components: { Nav, Footer }
};
</script>
