<template lang="pug">
v-hover(v-slot:default="{ hover }" v-if='product')
  v-card(shaped :elevation="hover ? 12 : 2")
    v-img(:src='mediaHost + product.icon' v-if='product.icon' cover)
    v-toolbar(dense flat)
      .subtitle-2 {{ product.name }}
      v-spacer
      .subtitle-2 {{ product.price | currency }}
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST
    };
  }
};
</script>