<template lang="pug">
div
  v-parallax(:src='case_study_bkg')
    v-container
      v-row(align='end' style='height: 300px;')
        div
          .head-title.display-1 Case Studies
</template>

<script>
export default {
  data() {
    return {
      case_study_bkg:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Fcase_study_2.jpg?alt=media&token=95130c7c-5d21-4b16-8873-ee6c7079b539"
    };
  }
};
</script>

<style scoped>
.head-title {
  background-image: linear-gradient(rgb(245, 245, 245, 0.6), black);
}
</style>
