<template lang="pug">
v-container
  Catalog
</template>

<script>
import Catalog from "./Catalog";
export default {
  components: { Catalog }
};
</script>