<template lang="pug">
div
  v-navigation-drawer(v-model="drawer" app fixed temporary width="230" :src='bkg' dark)
    v-list
      v-list-item(v-for="(item, index) in items" :key="index" :to="item.to")
        v-list-item-icon
          v-icon {{ item.icon }}
        v-list-item-content
          v-list-item-title {{ item.title }}
      v-divider
      v-list-item(@click='logout' v-if='$auth.check()')
        v-list-item-icon
          v-icon mdi-logout
        v-list-item-content
          v-list-item-title Logout
  v-app-bar(app dense flat)
    v-app-bar-nav-icon(@click='drawer = !drawer')
    v-btn(icon @click='tohome')
      v-icon mdi-home
    v-spacer
    v-tabs(align-with-title right).d-none.d-sm-flex
      v-tab(to='/shop' v-for='(item, index) in items' :key='index' :to='item.to') {{ item.title }}
</template>

<script>
import navItems from "./NavItems.json";

export default {
  data() {
    return {
      drawer: false,
      items: navItems,
      bkg:
        "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/background%2Fsimple-nav-background.jpg?alt=media&token=617bce35-0db9-4476-ae8c-2a62bcafc472"
    };
  },
  methods: {
    tohome() {
      this.$router.push("/");
    },
    logout() {
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT;
      // eslint-disable-next-line
      gapi.load("auth2", initAuth2);
      const finish = this.$auth.logout();
      function initAuth2() {
        // eslint-disable-next-line
        gapi.auth2.init({ client_id: clientId }).then(() => {
          // eslint-disable-next-line
          gapi.auth2.getAuthInstance().signOut();
          finish;
        });
      }
    }
  }
};
</script>
