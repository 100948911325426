<template lang="pug">
v-container
  Login
</template>

<script>
import Login from "./Login";

export default {
  components: { Login }
};
</script>
