import _ from 'underscore'

const state = {
  products: []
}

const getters = {
  products: state => state.products
}

const actions = {
  setProducts: ({
    commit
  }, products) => {
    commit('setProducts', products)
  },
  updateProduct: ({
    commit
  }, gc) => {
    commit('updateProduct', gc)
  }
}

const mutations = {
  setProducts(state, products) {
    state.products = products
  },
  updateProduct(state, gc) {
    state.products = _.map(state.products, o => {
      if (o._id === gc._id) return gc
      return o
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}