import Home from './Home/index.vue'
import Case from './Case/index.vue'
import Shop from './Shop/index.vue'

export default [{
  path: '/',
  component: Home
}, {
  path: '/case',
  component: Case
}, {
  path: '/shop',
  component: Shop
}]